import ArenaHirePromo from './ArenaHirePromo';
// ///////////////////////////////////////////////////////////////////////////
// JS for the quickbook (aka booking bar) ui
// ///////////////////////////////////////////////////////////////////////////

(function ($) {

    var host = $("#booking-api-endpoint").val();
    var arenasUrl = host + "/api/booking/locations";
    var datesUrl = host + "/api/booking/locations/{locationId}/dates";
    var playersUrl = host + "/api/booking/locations/{locationId}/players";
    var durationsUrl = host + "/api/booking/locations/{locationId}/dates/{date}/durations";
    var availableSlotsUrl = host + "/api/booking/locations/{locationId}/dates/{date}/timeslots?numberOfPlayers={numberOfPlayers}&duration={duration}";
    var operatingHoursUrl = host + "/api/booking/locations/{locationId}/dates/{date}/operatinghours";
    var bookingSystemUrl = host + "/booking/landing";
    var $arenasDropdown = $("#location-select");
    var $datesDropdown = $("#date-select");
    var $playersDropdown = $("#players-select");
    var $durationsDropdown = $("#duration-select");
    var $findButton = $("#quickbook-find");
    var $findingButton = $("#quickbook-finding");
    var $smallHeader = $("#quickbook-small-bar");
    var $smallClose = $("#quickbook-small-close");
    var $quickbookBarContainer = $(".quickbook-bar-container");
    var $quickbookSlotsContainer = $(".quickbook-slots-container");
    var quickbookSlotsClose = $("#quickbook-slots-close", $quickbookSlotsContainer);

    var selectedTimePeriodCache = ""; //initialised by handleFindClick()
    var template = null;

    //clear/disable array of elements/jq wrapped sets
    function clearAndDisableElements(elementArray) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
            for (var _iterator = elementArray[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var element = _step.value;

                var placeHolder = element[0].firstElementChild;
                while (element[0].firstElementChild) {
                    $(element[0].firstElementChild).remove();
                }
                element[0].appendChild(placeHolder);
                element[0].setAttribute("disabled", "disabled");
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                    _iterator.return();
                }
            } finally {
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    };

    function enableElements(elementArray) {
        elementArray.forEach(function ($item) {
            if ($item instanceof jQuery && $item.length > 0) {
                $item.each(function (idx, el) {
                    $(el).prop("disabled", false); //enable
                });
            } else {
                $item.prop("disabled", false); //enable
            }
        });
    };

    function showQuickBookOffline() {
        var $barInputColDivs = $(".quickbook-input", $quickbookBarContainer),
            $barErrorColDiv = $(".quickbook-error", $quickbookBarContainer),
            $slotsDiv = $("#quickbook-availslots-container", $quickbookSlotsContainer),
            $slotsErrorDiv = $("#quickbook-availslots-error", $quickbookSlotsContainer);

        $barInputColDivs.hide();
        $barErrorColDiv.show();
        $slotsDiv.hide();
        $slotsErrorDiv.show();
    };

    function getDataFromApi(url, dataDescription, doneFunction, failFunction, alwaysFunction) {
        $.get(url).done(function (response, status, jqXhr) {
            doneFunction(response);
        }).fail(function (jqXhr, status, errorThrown) {
            if (typeof failFunction === "function") {
                failFunction(jqXhr, status, errorThrown);
            } else {
                alert("Failed to retrieve data. Please try again. Contact customer services if this problem persists.");
            }
        }).always(function () {
            if (typeof alwaysFunction === "function") {
                alwaysFunction();
            }
        });
    };

    function showSpinner($container) {
        $container.find(".fa-spin").removeClass("hide");
    };

    function hideSpinner($container) {
        $container.find(".fa-spin").addClass("hide");
    };

    function retrieveAndSetArenas() {

        showSpinner($arenasDropdown.parent());

        getDataFromApi(arenasUrl, "arenas", function (data) {
            $.each(data, function (idx, item) {
                $arenasDropdown.append($("<option />").val(this.id).text(this.name));
            });
            enableElements([$arenasDropdown]);
        }, function (jqXhr, status, errorThrown) {
            showQuickBookOffline();
        }, function () {
            hideSpinner($arenasDropdown.parent());
        });
    };

    function handleArenaChange() {

        var locationId = $arenasDropdown.val();
        var url = datesUrl.replace("{locationId}", locationId);

        //clear and disable later dropdowns
        clearAndDisableElements([$datesDropdown, $playersDropdown, $durationsDropdown, $findButton]);

        if (locationId === "") return;

        showSpinner($datesDropdown.parent());

        //retrieve dates for the selected arena
        getDataFromApi(url, "dates", function (result) {
            //create options
            $.each(result, function (idx, item) {
                var dateTime = moment(item);
                var niceDateString = dateTime.format("ddd D MMM");
                $datesDropdown.append($("<option />").val(item).text(niceDateString));
            });

            //re-enable date dropdown for selection
            enableElements([$datesDropdown]);
        }, function (jqXhr, status, errorThrown) {
            //fail function
            showQuickBookOffline(); //show the bar offline
        }, function () {
            //always function
            hideSpinner($datesDropdown.parent());
        });
    };

    function handleDateChange() {
        var locationId = $arenasDropdown.val();
        var dateTime = $datesDropdown.val();
        var url = playersUrl.replace("{locationId}", locationId);

        //clear and disable later dropdowns
        clearAndDisableElements([$playersDropdown, $durationsDropdown, $findButton]);

        if (dateTime === "") return;

        showSpinner($playersDropdown.parent());

        //retrieve nof players for the selected arena + date
        getDataFromApi(url, "players", function (result) {
            result.pop();
            //create options
            $.each(result, function (idx, item) {
                $playersDropdown.append($("<option />").val(item).text(item));
            });
			
            $playersDropdown.append($("<option />").val(result.length + 1).text(result.length + 1 + " +"));
            //re-enable next dropdown for selection
            enableElements([$playersDropdown]);
        }, function (jqXhr, status, errorThrown) {
            //fail function
            showQuickBookOffline(); //show the bar offline
        }, function () {
            //always function
            hideSpinner($playersDropdown.parent());
        });
    };

    function handlePlayersChange(event) {
        var locationId = $arenasDropdown.val();
        var dateTime = $datesDropdown.val();
        var numberOfPlayers = $playersDropdown.val();
        var urlFriendlyDate = moment(dateTime).format("YYYY-MM-DD");
        var url = durationsUrl.replace("{locationId}", locationId).replace("{date}", urlFriendlyDate);

        var value = event.target.value;
        var options = event.target.querySelectorAll("option");
        var optionsArr = Array.from(options).map(function (x) {
            return x.value;
        });

        if (optionsArr.indexOf(value) + 1 === optionsArr.length) {
            new ArenaHirePromo();
            options[0].selected = true;
            return;
        }

        //clear and disable later dropdowns
        clearAndDisableElements([$durationsDropdown, $findButton]);

        if (numberOfPlayers === "") return;

        showSpinner($durationsDropdown.parent());

        //retrieve available durations for the selected arena + date + nof players
        //durations: ["01:00:00", "02:00:00", "03:00:00", "06:00:00", "12:00:00"],
        getDataFromApi(url, "durations", function (result) {
            $.each(result, function (idx, item) {
                var m = moment.duration(item);
                var durationString = "";

                if (m.hours() > 0) {
                    durationString = m.hours() + " hour";
                    if (m.hours() > 1) durationString += "s";
                } else if (m.minutes() > 0) durationString = m.minutes() + " mins";

                $durationsDropdown.append($("<option />").val(item).text(durationString));
            });

            enableElements([$durationsDropdown]);
        }, function (jqXhr, status, errorThrown) {
            showQuickBookOffline();
        }, function () {
            hideSpinner($durationsDropdown.parent());
        });
    };

    function handleDurationChange() {
        var duration = $durationsDropdown.val();
        clearAndDisableElements([$findButton]);

        if (duration === "") {
            return;
        }

        enableElements([$findButton]);
    };

    function handleFindClick() {
        var $this = $(this),
            locationId = $arenasDropdown.val(),
            selectedDate = $datesDropdown.val(),
            numberOfPlayers = $playersDropdown.val(),
            duration = $durationsDropdown.val();

        var state = {
            step: "slots",
            locationId: locationId,
            dateString: selectedDate,
            numberOfPlayers: numberOfPlayers,
            duration: duration,
            period: "morning" //initial
        };
        pushState(state, "slots", null);

        // Facebook tracking code
        fbq("track", "AddToCart");

        //get and render the available slots
        getAndRenderSlotsForArenaDateAndPeriod(locationId, selectedDate, numberOfPlayers, duration);
    };

    function selectFirstEnabledTimePeriodOnFromSpecified(selectedPeriod) {
        var firstEnabledPeriod = null;

        if (selectedPeriod === "morning" || selectedPeriod === "") {
            if ($("#quickbook-slots-period-morning").length > 0) {
                firstEnabledPeriod = "morning";
            } else if ($("#quickbook-slots-period-afternoon").length > 0) {
                firstEnabledPeriod = "afternoon";
            } else {
                firstEnabledPeriod = "evening";
            }
        } else if (selectedPeriod === "afternoon") {
            if ($("#quickbook-slots-period-afternoon").length > 0) {
                firstEnabledPeriod = "afternoon";
            } else {
                firstEnabledPeriod = "evening";
            }
        } else {
            firstEnabledPeriod = "evening";
        }

        return firstEnabledPeriod;
    };

    function renderTemplate(data, callback) {

        var existing = document.getElementById("quick-book-slots-container");

        function insertOrReplace(html) {
            if (existing !== null) {
                var cntr = document.createElement("div");
                cntr.insertAdjacentHTML("beforeend", html);

                existing.innerHTML = cntr.firstElementChild.innerHTML;
            } else {
                document.body.insertAdjacentHTML("beforeend", html);
            }
        }

        if (template === null) {
            $.get("/Handlebars/BookingBar-Template.handlebars", function (tmpl) {
                template = Handlebars.compile(tmpl);
                var html = template(data);
                insertOrReplace(html);
                callback(document.getElementById("quick-book-slots-container"));
            });
        } else {
            var html = template(data);
            insertOrReplace(html);
            callback(document.getElementById("quick-book-slots-container"));
        }
    }

    function getAndRenderSlotsForArenaDateAndPeriod(locationId, dateString, numberOfPlayers, duration) {
        var selectedDate = moment(dateString);
        var urlFriendlyDate = selectedDate.format("YYYY-MM-DD");

        var slotsUrl = availableSlotsUrl.replace("{locationId}", locationId).replace("{date}", urlFriendlyDate).replace("{numberOfPlayers}", numberOfPlayers).replace("{duration}", duration);

        var hoursUrl = operatingHoursUrl.replace("{locationId}", locationId).replace("{date}", urlFriendlyDate);

        var operatingHours = {};

        getDataFromApi(hoursUrl, "operating hours", function (hoursResult) {
            operatingHours = {
                haveTimes: true,
                openingTime: hoursResult.openingTime,
                closingTime: hoursResult.closingTime
            };
        }, function (jqXhr, status, errorThrown) {
            operatingHours = {
                haveTimes: false
            };
        });

        getDataFromApi(slotsUrl, "available slots", function (result) {

            var data = {
                earlierDate: selectedDate.clone().subtract(1, "days").format(),
                selectedDate: dateString,
                laterDate: selectedDate.clone().add(1, "days").format(),
                slots: result,
                operatingHours: operatingHours
            };

            renderTemplate(data, function (container) {

                var slotsDateChangeBtn = $("#quick-book-slots-container .quickbook-slots-date-change");
                var periodAnchors = $("#quick-book-slots-container a.quickbook-slots-period");
                var slotSelectButtons = $("#quick-book-slots-container .quickbook-slot-choose");

                slotsDateChangeBtn.on("click", function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    var disabled = $this.hasClass("disabled");
                    var newDateString = $this.data("new-date");
                    if (!disabled) {
                        showSpinner($this.parent());

                        getAndRenderSlotsForArenaDateAndPeriod(locationId, newDateString, numberOfPlayers, duration);

                        //save into browser history
                        var state = {
                            step: "slots",
                            locationId: locationId,
                            dateString: newDateString,
                            numberOfPlayers: numberOfPlayers,
                            duration: duration,
                            period: selectedTimePeriodCache
                        };
                        pushState(state, "slots", null);
                    }
                });

                //hook up time period click events
                periodAnchors.on("click", function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    var disabled = $this.hasClass("disabled");
                    var periodName = $this.data("period");

                    if (!disabled) {
                        //cache the value to handle when earlier/later dates selected
                        selectedTimePeriodCache = periodName;

                        //set the ui - highlight button and show slots
                        activateSlotTimePeriod(periodName, data.slots); //'morning', 'afternoon', 'evening'
                    }
                });

                //hook up slot selection
                slotSelectButtons.on("click", function () {
                    var $this = $(this);
                    var selectedDate = $this.data("datetime");
                    fbq("track", "InitiateCheckout");
                    handleSlotSelect(locationId, selectedDate, numberOfPlayers, duration);
                });

                //hook up slots close
                quickbookSlotsClose.on("click", function () {
                    $quickbookSlotsContainer.removeClass("slide-in");
                });

                //select first enabled period, starting with what was previously selected
                selectedTimePeriodCache = selectFirstEnabledTimePeriodOnFromSpecified(selectedTimePeriodCache);

                //show selected time period
                activateSlotTimePeriod(selectedTimePeriodCache, data.slots);

                //slide slots selection page in
                $quickbookSlotsContainer.addClass("slide-in");

                container.querySelector(".close-button").addEventListener("click", function () {
                    $(container.classList).remove("open");
                    setTimeout(function () {
                        $(container).remove();
                    }, 300);
                });

                container.querySelector(".close-link").addEventListener("click", function () {
                    $(container.classList).remove("open");
                    setTimeout(function () {
                        $(container).remove();
                    }, 300);
                });

                setTimeout(function () {
                    container.classList.add("open");
                }, 100);
            });
        }, function (jqXhr, status, errorThrown) {
            //fail function
            showQuickBookOffline(); //show offline
        }, function () {
            //always function
            $findButton.show();
            $findingButton.hide();
        });
    };

    function pushState(state, title, url) {
        history.pushState(state, title, url);
    };

    function updateState(state, title, url) {
        var currentState = history.state;
        var newState = $.extend({}, currentState, state);
        history.replaceState(newState, title, url);
    };

    function restoreState(state) {
        if (!state) {
            return false;
        }

        if (state.step === "initial") {
            $quickbookBarContainer.removeClass("slide-in");
            $quickbookSlotsContainer.removeClass("slide-in");
        } else if (state.step === "openfind") {
            $quickbookBarContainer.addClass("slide-in");
            $quickbookSlotsContainer.removeClass("slide-in");
        } else if (state.step === "slots") {
            selectedTimePeriodCache = state.period;
            getAndRenderSlotsForArenaDateAndPeriod(state.locationId, state.dateString, state.numberOfPlayers, state.duration);
        } else {
            return false;
        }

        return true;
    };

    function handlePopstate(event) {
        var state = event.originalEvent.state;
        restoreState(state);
    };

    function activateSlotTimePeriod(period, slots) {
        var $periodDivs = $("#quick-book-slots-container a.quickbook-slots-period");
        $periodDivs.each(function (idx, el) {
            if ($(el).data("period") === period) $(el).addClass("active"); else $(el).removeClass("active");
        });

        var periodPropertyName = capitalizeFirstLetter(period);

        var $slotTables = $(".quickbook-slots-availslots table");
        $slotTables.each(function (idx, el) {
            if ($(el).data("period") === period && slots[periodPropertyName].length > 0) $(el).show(); else $(el).hide();
        });

        var $noSlotDivs = $("div.no-slots");
        $noSlotDivs.each(function (idx, el) {
            if (slots[periodPropertyName].length === 0) {
                $(el).show();
            } else {
                $(el).hide();
            }
        });

        updateState({ period: period }); //update current browser history entry
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function handleSmallHeaderClick() {
        $quickbookBarContainer.addClass("slide-in");
        pushState({ step: "openfind" }, "openfind", null);
    };

    function handleSlotSelect(locationId, selectedDate, numberOfPlayers, duration) {
        var form = $("#quickbook-slots-form");

        //set values into single hidden form and submit it
        $("#quickbook-slots-form-locationId", form).val(locationId);
        $("#quickbook-slots-form-startDate", form).val(selectedDate);
        $("#quickbook-slots-form-duration", form).val(duration);
        $("#quickbook-slots-form-numberOfPlayers", form).val(numberOfPlayers);

        form.attr("action", bookingSystemUrl);
        form.submit();
        $(document.getElementById("quick-book-slots-container")).remove();
    };

    function onWindowScrolled() {
        var container = document.getElementById("booking-bar-container");
        var content = container.nextElementSibling;
        var isPositionFixed = window.getComputedStyle(container).position === "fixed";

        // Get the current break points
        var width = window.innerWidth;
        var heightCheck = 600;
        var top = "100px";
        var marginTop = "75px";

        if (width <= 1024) {
            top = "100px";
            heightCheck = 450;
            marginTop = "235px";
        }
        if (width <= 768) {
            top = "50px";
            heightCheck = 450;
            marginTop = "235px";
        }
        if (width <= 639) {
            top = "50px";
            heightCheck = 300;
            marginTop = "104px";
        }

        if (window.scrollY > heightCheck && !isPositionFixed) {
            container.style.position = "fixed";
            container.style.top = top;
            content.style.marginTop = marginTop;
        }
        if (window.scrollY < heightCheck && isPositionFixed) {
            container.style.position = "static";
            container.style.top = "0";
            content.style.marginTop = "50px;";
        }
    }

    function init() {
        if (document.getElementById("booking-bar-container") === null) {
            return;
        }

        window.addEventListener("scroll", onWindowScrolled);
        onWindowScrolled();

        //set selection/click events
        $arenasDropdown.on("change", handleArenaChange);
        $datesDropdown.on("change", handleDateChange);
        $playersDropdown.on("change", handlePlayersChange);
        $durationsDropdown.on("change", handleDurationChange);
        $findButton.on("click", handleFindClick);

        //fill arena ddl when page loads
        if ($("option", $arenasDropdown).length === 1) {
            clearAndDisableElements([$datesDropdown, $playersDropdown, $durationsDropdown, $findButton]);
            retrieveAndSetArenas();
        }

        //handle small screen slidein
        $smallHeader.on("click", handleSmallHeaderClick);
        $smallClose.on("click", function () {
            $quickbookBarContainer.removeClass("slide-in");
        });

        //no restored state - so save initial state
        updateState({ step: "initial" }, "initial", null);

        //setup event handler to handle back navs
        $(window).on("popstate", handlePopstate);
    };

    $(document).ready(init);
})(jQuery);