export default (function() {

    const arenaHirePromo = function() {

        // show the promo
        const backdrop = (() => {
            const ele = document.createElement("div");
            ele.id = "arena-time-promo-backdrop";
            ele.style.position = "fixed";
            ele.style.top = "0";
            ele.style.left = "0";
            ele.style.width = "100%";
            ele.style.height = "100%";
            ele.style.opacity = 0.5;
            ele.style.transition = "opacity ease-in-out 200ms";
            ele.style.backgroundColor = "#000000";
            ele.style.zIndex = "200";
            document.body.appendChild(ele);
            return ele;
        })();

        const modal = (() => {
            const modal = document.createElement("div");
            modal.id = "arena-time-promo-modal";

            let width = (window.innerWidth / 2) - 210;
            if (width < 0) {
                width = 0;
            }

            modal.style.backgroundColor = "#0a081a";
            modal.style.position = "fixed";
            modal.style.top = "0";
            modal.style.left = "0";
            modal.style.marginLeft = `${width / 1.5}px`;
            modal.style.marginRight = `${width / 1.5}px`;
            modal.style.marginTop = `${window.innerHeight / 100 * 30}px`;
            modal.style.zIndex = 2;
            modal.style.boxShadow = "0 0 5px rgba(0,0,0,0.5)";
            modal.style.textAlign = "center";
            modal.style.padding = "20px";
            modal.style.zIndex = "201";

            const container = document.createElement("div");
            container.style.width = "100%";
            container.style.height = "100%";
            container.style.position = "relative";
            container.style.paddingBottom = "15px";

            const header = document.createElement("p");
            header.style.marginTop = "0";
            header.style.paddingTop = "20px";
            header.innerText = "Unfortunately we are unable to take bookings for more than 6 attendees through our website. Please contact your local arena for group booking enquires. Alternatively you may be interested in our Arena Hire packages.";
            header.style.marginBottom = "40px";

            function styleLink(link) {
                link.target = "_blank";
                link.style.backgroundColor = "#eb00ff";
                link.style.color = "#ffffff";
                link.style.padding = "10px 25px";
                link.style.margin = "10px";
                link.style.width = "50px";
                link.style.fontSize = "16px";
                link.style.textDecoration = "none";
            }

            const link1 = document.createElement("a");
            link1.href = "https://www.belong.gg/arena-hire";
            link1.innerText = "Arena Hire packages";
            styleLink(link1);

            const closeButton = document.createElement("i");
            closeButton.style.position = "absolute";
            closeButton.style.right = 0;
            closeButton.style.top = 0;
            closeButton.style.cursor = "pointer";

            closeButton.classList.add("fa");
            closeButton.classList.add("fa-times");

            modal.appendChild(container);
            container.appendChild(header);
            container.appendChild(link1);
            container.appendChild(closeButton);
            document.body.appendChild(modal);
            return modal;
        })();

        function handleClick() {
            $(modal).remove();
            $(backdrop).remove();
        }

        const closeableElements = modal.querySelectorAll("a, i");
        for (let element of closeableElements) {
            element.addEventListener("click", handleClick);
        }
    }

    return arenaHirePromo;
})();